import React from 'react';
import VideoComp from './VideoComp';
import Owl from './Owl';
import Slide from './Slide';
import ContactListComp from './ContactListComp';
import RevealSlide from './RevealSlide';
import SlideGrid1 from './SlideGrid1';
import AudioPlayer from './AudioPlayer';

import MainNavComp from './MainNavComp';
import CircleDotsComp from './CircleDotsComp';
import InfoPageComp from './InfoPageComp';
import ImpressedInfoPage from './ImpressedInfoPage';
import SpyGlassComp from './SpyGlassComp';
import ParralaxComp from './ParralaxComp';
import SoundControl from './SoundControl';
import BidForm from './BidForm'; 
import BackgroundAudio from './BackgroundAudio'; 



class Home extends React.Component{ 

    constructor(){
        super();
    }

    componentDidMount(){
      this.preloadImages()

      this.props.getArtworkDetails()
    }

    componentDidUpdate(){
      if(this.props.carousel.navTo) {
        this._owl.gotoSlide(this.props.carousel.navToIndex)
        this.props.didNavToSlide()
      }

      this.preloadImages()

      // setTimeout(()=>{
      //   this._e.style.display="none"
      // }, 10000)
    }

    componentWillMount(){
    }

    preloadImages(){
      if(!this.props.preload.allLoaded){
        this.props.loadImages();
      }
    }

    onOwlDrag(event){
      this.props.carouselDrag();
    }

    onOwlDragged(event){
      this.props.carouselDragged(event.item.index);
    }

    renderHome(){
        const owlOptions={
          items:1, 
          onDragged: this.onOwlDragged.bind(this),
          onDrag: this.onOwlDrag.bind(this),
          smartSpeed:500,
        }
        return (
          <div className="home">
            <Owl options={owlOptions}  ref={(o) => this._owl = o} {...this.props} videoComp={this.props.videoComp}>
              <Slide slideClass={'page_intro'} slideData={this.props.carousel.slides[0]} />
              <SlideGrid1 slideClass={'cat_page_anton'} slideData={this.props.carousel.slides[1]} openVideoComp={this.props.openVideoComp} infoPageClass={'component-top info_page_anton'} openInfoPage={this.props.openInfoPage} />
              <Slide slideClass={'cat_page_streetwires'} slideData={this.props.carousel.slides[2]} infoPageClass={'component-top info_page_streetwires'} openInfoPage={this.props.openInfoPage} />
              <RevealSlide slideClass={'cat_page_jaco'} slideData={this.props.carousel.slides[3]} infoPageClass={'component-top info_page_jaco'} openInfoPage={this.props.openInfoPage} />
              <Slide slideClass={'cat_page_impressed'} slideData={this.props.carousel.slides[4]} infoPageClass={'component-top info_page_impressed'} openInfoPage={this.props.openInfoPage} />
              <SpyGlassComp artworks={this.props.form.artworks} showHideForm={this.props.showHideForm}  openVideoComp={this.props.openVideoComp} slideData={this.props.carousel.slides[5]}/>
              
              <Slide slideClass={'cat_page_poko'} slideData={this.props.carousel.slides[6]} infoPageClass={'component-top info_page_poko'} openInfoPage={this.props.openInfoPage} />
              <Slide slideClass={'cat_page_robobeast'} slideData={this.props.carousel.slides[7]} infoPageClass={'component-top info_page_robobeast'} openInfoPage={this.props.openInfoPage} />
              <Slide slideClass={'cat_page_karen'} slideData={this.props.carousel.slides[8]} openVideoComp={this.props.openVideoComp} infoPageClass={'component-top info_page_karen'} openInfoPage={this.props.openInfoPage} />
              <Slide slideClass={'cat_page_capitalcraft'} slideData={this.props.carousel.slides[9]} infoPageClass={'component-top info_page_capitalcraft'} openInfoPage={this.props.openInfoPage}/>
              <Slide slideClass={'cat_page_retief'} slideData={this.props.carousel.slides[10]} />
              <ContactListComp slideClass={'contact-list-page'} slideData={this.props.carousel.slides[11]} />
            </Owl>
            <AudioPlayer {...this.props} />
            


            <InfoPageComp artworks={this.props.form.artworks} showHideForm={this.props.showHideForm} slideClass={'component-top info_page_anton'} infoPages={this.props.infoPages} closeInfoPage={this.props.closeInfoPage} slideData={this.props.infoPages.info_page_anton}  infoTags={this.props.infoTags.info_page_anton}/>
            <InfoPageComp artworks={this.props.form.artworks} showHideForm={this.props.showHideForm} slideClass={'component-top info_page_streetwires'} infoPages={this.props.infoPages} closeInfoPage={this.props.closeInfoPage} slideData={this.props.infoPages.info_page_streetwires} infoTags={this.props.infoTags.info_page_streetwires}  />
            <InfoPageComp artworks={this.props.form.artworks} showHideForm={this.props.showHideForm} slideClass={'component-top info_page_jaco'} infoPages={this.props.infoPages} closeInfoPage={this.props.closeInfoPage} slideData={this.props.infoPages.info_page_jaco} infoTags={this.props.infoTags.jaco} />
            <ImpressedInfoPage artworks={this.props.form.artworks} showHideForm={this.props.showHideForm} slideClass={'component-top info_page_impressed'} openVideoComp={this.props.openVideoComp} infoPages={this.props.infoPages} closeInfoPage={this.props.closeInfoPage} slideData={this.props.infoPages.info_page_impressed} />
            <InfoPageComp artworks={this.props.form.artworks} showHideForm={this.props.showHideForm} slideClass={'component-top info_page_blank'} infoPages={this.props.infoPages} closeInfoPage={this.props.closeInfoPage} slideData={this.props.infoPages.info_page_blank} />
            <InfoPageComp artworks={this.props.form.artworks} showHideForm={this.props.showHideForm} slideClass={'component-top info_page_poko'} infoPages={this.props.infoPages} closeInfoPage={this.props.closeInfoPage} slideData={this.props.infoPages.info_page_poko} infoTags={this.props.infoTags.poko} />
            <InfoPageComp artworks={this.props.form.artworks} showHideForm={this.props.showHideForm} slideClass={'component-top info_page_robobeast'} infoPages={this.props.infoPages} closeInfoPage={this.props.closeInfoPage} slideData={this.props.infoPages.info_page_robobeast} infoTags={this.props.infoTags.robobeast} />
            <InfoPageComp artworks={this.props.form.artworks} showHideForm={this.props.showHideForm} slideClass={'component-top info_page_karen'} infoPages={this.props.infoPages} closeInfoPage={this.props.closeInfoPage} slideData={this.props.infoPages.info_page_karen} infoTags={this.props.infoTags.karen} />
            <InfoPageComp artworks={this.props.form.artworks} showHideForm={this.props.showHideForm} slideClass={'component-top info_page_capitalcraft'} infoPages={this.props.infoPages} closeInfoPage={this.props.closeInfoPage} slideData={this.props.infoPages.info_page_capitalcraft} infoTags={this.props.infoTags.capital}  />
            
            <VideoComp videoComp={this.props.videoComp} closeVideoComp={this.props.closeVideoComp} />
            <CircleDotsComp slides={this.props.carousel.slides} infoPages={this.props.infoPages} currentSlideIndex={this.props.carousel.currentSlide} />
            <MainNavComp currentSlideIndex={this.props.carousel.currentSlide} carouselSlides={this.props.carousel.slides} navToSlide={this.props.navToSlide} closeInfoPage={this.props.closeInfoPage} infoPages={this.props.infoPages} closeVideoComp={this.props.closeVideoComp} />
            <SoundControl carousel={this.props.carousel} toggleSound={this.props.toggleSound} />
            <BidForm carousel={this.props.carousel} form={this.props.form} showHideForm={this.props.showHideForm} makeOffer={this.props.makeOffer} />
            <BackgroundAudio audioSource={this.props.carousel.backgroundSound.src}/>
          </div>
        )
    }

    renderPreloader(){
      return (<div className="preloader">
              <img src="images/dots_web.gif" width="338" />
              <img src="images/Pendoring_Responsive_Website_Explainer.gif" className="explainer" ref={(e)=>{this._e = e;}}/>
            </div>)
    }

    render(){
      if(window.innerWidth < 1100){
        return <div className="mobile-holder"><p className="mobile">Hierdie webwerf se awesomeness is ongelukkig nie geskik vir mobiele toestelle nie. Besoek asseblief hierdie webwerf op jou persoonlike rekenaar.</p></div>
      }else{
        if (this.props.preload.isReady && this.props.form.haveArtworkDetails){
          return <div>{this.renderHome()}</div>
        }else{
          return <div>{this.renderPreloader()}</div>
        }
      }


    }
}
export default Home;
