import {ActionTypes} from '../actions/actions';

export function videoComp (state={}, action){

  switch(action.type){
  	case ActionTypes.OPEN_VIDEO_COMPONENT:
  		console.log(state);
  		return Object.assign({},state,{
  			vidSrc: action.vidSrc,
  			open: true
  		})
  	case ActionTypes.CLOSE_VIDEO_COMPONENT:
  		console.log(state);
  		return Object.assign({},state,{
  			vidSrc: "",
  			open: false
  		})

    default:
      return state;
  }
}