import {ActionTypes} from '../actions/actions';

export function infoPages (state={}, action){

  switch(action.type){
  	case ActionTypes.OPEN_INFO_PAGE:
  		return Object.assign({},state,{
  			openPage: action.infoPageClass,
  			open: true,
  		})
  	case ActionTypes.CLOSE_INFO_PAGE:
  		return Object.assign({},state,{
  			openPage: action.infoPageClass,
  			open: false,
  		})
   

    default:
      return state;
  }
}