import React from 'react';
import TweenMax from 'gsap';


class ContactListComp extends React.Component{

    constructor(){
        super();
        //
    }


    componentDidMount(){
    }

    componentDidUpdate() {
    }

    render(){
        return (
          <div className={`slide ${this.props.slideClass}`} ref="thisSlide">
            <div className="main_container">

                <div className="contact_cont">
                    <h2>Anton SMIT</h2>
                    <p>Black Eagle-laan<br/>
Aqua Vista Mountain Estate<br/>
Bronkhorstspruitdam<br/>
Suid-Afrika<br/>
<br/>
+27 (0)82 653 7659 <br/>
<a href="mailto:office.antonsmit@gmail.com" target="_blank">office.antonsmit@gmail.com</a></p>
                </div>

                <div className="contact_cont">
                    <h2>jaco<br/>van der merwe</h2>
                    <p>Bittereinder<br/>
<a href="http://www.bittereinder.com" target="_blank">www.bittereinder.com</a><br/>
Twitter: @ajaxrap<br/>
Instagram: @bitterjakkals<br/>
                    <a href="mailto:jaxbringthelight@gmail.com">jaxbringthelight@gmail.com</a><br/>
</p>
                </div>


                <div className="contact_cont">
                    <h2>Hardus Koekemoer</h2>
                    <p>+27 (0)83 264 0753<br/>
                    <a href="mailto:koekemoerh@tut.ac.za">koekemoerh@tut.ac.za</a></p>
                </div>


                <div className="clear"></div>





                <div className="contact_cont">
                    <h2>Lionel Smit</h2>
                    <p>Smitstraat 46<br/>
Strand<br/>
Kaapstad<br/>
Suid-Afrika<br/>
<br/>
+27 (0)21 854 3063<br/>
<a href="mailto:hello@lionelsmit.co.za" target="_blank">hello@lionelsmit.co.za</a></p>
                </div>

                <div className="contact_cont">
                    <h2>Impressed</h2>
                    <p>Greenacres-laan 25<br/>
Birdhaven<br/>
Johannesburg<br/>
Suid-Afrika<br/>
<br/>
<br/>
+27 (0)83 305 8315<br/>
<a href="mailto:martin@impressed.co.za" target="_blank">martin@impressed.co.za</a></p>
                </div>


                <div className="contact_cont">
                    <h2>Poko Productions</h2>
                    <p>Chris van REnsburg<br/>
                    +20 (0)82 925 7351<br/>
                    <a href="mailto:chris@pokoproductions.co.za">chris@pokoproductions.co.za</a><br/><br/>
                    Christelle van Graan<br/>
                    +27 (0)82 362 8462<br/>
                    <a href="mailto:christelle@pokoproductions.co.za">christelle@pokoproductions.co.za</a><br/></p>
                </div>


                <div className="contact_cont">
                    <h2>Retief van Wyk</h2>
                    <p>Parklaan 77<br/>
South Highway Besigheidspark<br/>
Centurion<br/>
Pretoria<br/>
Suid-Afrika<br/><br/>
+27 (0)83 291 0917
<br/>
<a href="mailto:vanwykr1@tut.ac.za">vanwykr1@tut.ac.za</a><br/>
<br/>

</p>
                </div>

                <div className="clear"></div>


                <div className="contact_cont">
                    <h2>Streetwires</h2>
                    <p>Kortmarkstraat 477<br/>
Bo-Kaap<br/>
Kaapstad<br/>
Suid-Afrika<br/>

<br/>
+27 (0)21 426 2475<br/>
info@streetwires.co.za</p>
                </div>


                <div className="contact_cont">
                    <h2>Capital Craft Beer Academy</h2>
                    <p>Greenlyn Village-sentrum<br/>
Hoek van Thomas Edison- en 12de Straat<br/>
Menlopark<br/>
Pretoria<br/>
Suid-Afrika
<br/>
<br/>
+27 (0)12 424 8601
</p>
                </div>


                <div className="contact_cont">
                    <h2>Karen Steenkamp</h2>
                    <p>ETIKET Brand Design<br/>
Regency-laan 71<br/>
R21 Besigheidspark<br/>
Irene<br/>
Pretoria<br/>
Suid-Afrika<br/>
<br/>
+27 (0)12 345 5650<br/>
info@etiket.co.za
</p>
                </div>

                <div className="contact_cont">
                    <h2>Robobeast</h2>
                    <p>No. 5 Corner Minis<br/>
Hoek van Horse Chestnut- en Speculator-laan<br/>
Northlands Besigheidspark<br/>
Newmarket-straat<br/>
Northriding<br/>
Randburg<br/>
Suid-Afrika
<br/>
<br/>
+27 (81) 2529059<br/>
info@robobeast.co.za</p>
                </div>

                <div className="clear"></div>

            </div>
          </div>
        )
    }
}
export default ContactListComp;