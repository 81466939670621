import {ActionTypes} from '../actions/actions';

export function preload (state={}, action){

  switch(action.type){

    case ActionTypes.IMAGE_LOADED:
      const currentLoadingIndex = state.loadingIndex
      const isready = (action.index >= state.readyOnIndex)?true:false
      const numImages = state.images.length-1
      const isloaded = action.index == numImages ? true:false

      return Object.assign({}, state, {
        loadingIndex: action.index+1,
        isReady: isready,
        allLoaded: isloaded,
      });

    default:
      return state;
  }
}