import thunkMiddleware from 'redux-thunk';

import { createStore, applyMiddleware, compose } from 'redux';
import { syncHistoryWithStore } from 'react-router-redux';
import { browserHistory } from 'react-router';

// import root reducer
import rootReducer from './reducers/index';

// default state
const defaultState = {
  // dummy store, delete this and replace with your own -> remember to also change mapStateToProps in app/App.js to match state here
	carousel:{
    slides:[
  		{
        name: 'page_intro',
        h2Txt: "Die Storie",
        vidSrc: 'assets/videos/intro',
        audioSrc: "",
        audioTime:0,
        audioComplete: false,
        circleDotsPos: ['none', 'none'],
        // introText: "Reeds vir 21 jaar moedig Pendoring kreatiwiteit sonder grense aan. In samewerking met Etiket het Pendoring 10 kreatiewe siele gevra om vrye teuels aan hul verbeelding te gee. Want kreatiwiteit wil uit.",
        logo: false
      },
      {
        name: 'cat_page_anton',
        h2Txt: "Anton en Lionel Smit",
        vidSrc: 'assets/videos/lionel-en-anton-smit',
        audioSrc: "assets/anton_en_lionel_smit.mp3",
        audioTime:0,
        audioComplete: false, 
        circleDotsPos: ['none','none'],
      },
      {
        name:'cat_page_streetwires',
  			h2Txt: "Streetwires",
        vidSrc: 'assets/videos/streetwires',
  			audioSrc: "assets/streetwires.mp3",
        audioTime:0,
        audioComplete: false,
        introText:'Streetwires se hoofdoel is om aan werknemers hul waardigheid terug te gee deur die skep van betekenisvolle items met siel.',
        circleDotsPos: ['center','center'],
  		},
      {
        name:'cat_page_jaco',
        h2Txt: "Jaco van der Merwe",
        vidSrc: 'assets/videos/bittereinder',
        audioSrc: "assets/jaco.mp3",
        introText: 'hoof-kletsrymer van die elektro-groep, Bittereinder. Jaco se naam is op die lippe van almal wat ’n ding of twee weet van Alternatiewe Afrikaans.',
        audioTime:0,
        audioComplete: false,
        circleDotsPos: ['center','center'],
      },
      {
        name:'cat_page_impressed',
        h2Txt: "Impressed Craft Bindery and Letterpress Studio",
        vidSrc: '',
        audioSrc: "assets/impressed.mp3",
        audioTime:0,
        audioComplete: false,
        introText:'imPRESSed kan gevind word in Melrose, Johannesburg, waar hulle spesialiseer in boekbindery, die restourasie van antieke boeke, letterdruk-drukwerk, en klasse om \'n nuwe generasie aan hierdie industrie-van-ouds bekend te stel.',
        circleDotsPos: ['center','center'],
      },
      {
        name:'cat_page_hardus',
        h2Txt: "Hardus Koekemoer",
        vidSrc: 'assets/videos/hardusKoekemoer',
        audioSrc: "assets/hardus_koekemoer.mp3",
        audioTime:0,
        audioComplete: false,
        circleDotsPos: ['center','center'],
        artworkIDs: [5],
      },
      {
        name:'cat_page_poko',
        h2Txt: "Poko Productions",
        vidSrc: 'assets/videos/poko',
        audioSrc: "assets/poko.mp3",
        audioTime:0,
        audioComplete: false,
        introText:'Poko Productions is die breinkind van Chris van Rensburg, Christelle van Graan en Cindy Swanepoel. Sonder om té punny te raak, kan \'n mens redeneer dat hulle \'n skaduwee gooi oor die meeste verrigtinge waarby hulle betrokke is.',
        circleDotsPos: ['center','center'],
      },
      {
        name:'cat_page_robobeast',
        h2Txt: "Robobeast",
        vidSrc: 'assets/videos/digitale-en-interaktiewe-kom',
        audioSrc: "assets/robobeast.mp3",
        audioTime:0,
        audioComplete: false,
        introText:'Rich van As, hoof uitvoerende direkteur van Robobeast het \'n paar jaar gelede \'n paar vingers in \'n fratsongeluk verloor. Maar daar was ook geluk by die ongeluk.',
        circleDotsPos: ['center','center'],
      },
      {
        name:'cat_page_karen',
        h2Txt: "Karen Steenkamp",
        vidSrc: 'assets/videos/etiket',
        audioSrc: "assets/karen.mp3",
        audioTime:0,
        audioComplete: false,
        circleDotsPos: [-25,10],
      },
      {
        name:'cat_page_capitalcraft',
        h2Txt: "Capital Craft",
        vidSrc: 'assets/videos/capital_craft',
        audioSrc: "assets/capital_craft.mp3",
        audioTime:0,
        audioComplete: false,
        introText:'Capital Craft is waar al daai cool kids uithang. Maar dis ook toeganklik vir die bleeksiele wat kom tips vang.',
        circleDotsPos: ['center','center'],
      },
      {
        name:'cat_page_retief',
        h2Txt: "Retief van Wyk",
        vidSrc: 'assets/videos/retief',
        audioSrc: "assets/retief.mp3",
        audioTime:0,
        audioComplete: false,
        introText:'Retief van Wyk is die man wat daarvoor sorg dat Pendoring-wenners met \'n silwer of goue trofee wegstap.',
        circleDotsPos: ['center','center'],
      },
      {
        name:'cat_page_blank',
        h2Txt: "Kontak die kunstenaar",
        vidSrc: 'assets/videos/etiket',
        audioSrc: "",
        audioTime:0,
        audioComplete: false,
        circleDotsPos: ['none','none'],
      }
    ],
    currentSlide:0,
    dragging: false,
    dragged: false,
    navTo: false,
    navToIndex: 0,
    soundOn: true,
    backgroundSound:{
      src:'assets/bed_music.mp3',
    },
  },
		

	videoComp: {
	 	vidSrc: 'assets/p1_video',
	  	open: false
	},
    
  infoPages: {
  	info_page_anton: {
  		h2Txt: "",
      vidSrc: '',
      circleDotsPos: ['center','center'],
      artworkIDs: [1],
  	},
  	info_page_streetwires: {
  		h2Txt: "",
      vidSrc: '',
      circleDotsPos: [60,-65],
      artworkIDs: [2],
  	},
    info_page_jaco: {
      h2Txt: "",
      vidSrc: '',
      circleDotsPos: [50, -50],
      artworkIDs: [3],
    },
    info_page_robobeast: {
      h2Txt: "",
      vidSrc: '',
      circleDotsPos: ['center','center'],
      artworkIDs: [7],
    },
    info_page_karen: {
      h2Txt: "",
      vidSrc: '',
      circleDotsPos: ['center','center'],
      artworkIDs: [8],
    },
    info_page_capitalcraft: {
      h2Txt: "",
      vidSrc: '',
      circleDotsPos: ['center','center'],
      artworkIDs: [9],
    },
    info_page_impressed: {
      h2Txt: "",
      vidSrc: '',
      circleDotsPos: [-25,10],
      artworkIDs: [4, 12, 13],
    },
    info_page_blank: {
      h2Txt: "BLANK INFO PAGE",
      vidSrc: '',
      circleDotsPos: ['none','none'],
      artworkIDs: [5],
    },
    info_page_poko: {
      h2Txt: "",
      vidSrc: '',
      circleDotsPos: ['none','none'],
      artworkIDs: [6],
    },
  	open: false,
  	openPage: ''
	},



  infoTags: {
    info_page_anton:['Title:&nbsp;Displaced, 2016','Medium: GRP, staal','Grootte: 2400x2650x620'],
    info_page_streetwires:['Titel:&nbsp;Draadloos, 2016','Medium:&nbsp;Draad-en kralewerk','Grootte: 200x100x180'],
    karen:['Titel:&nbsp;Woordeboek-origami,&nbsp;2016','Medium: Papier/Bundel','Grootte: 28x23x23'],
    jaco:['Titel: Ons bestaan in woorde wat na mekaar toe uitstrek, 2016','Medium: Ink,<br/>Fabriano Karton','Grootte:<br/>317x440'],
    poko:['Titel:&nbsp;And... Action, 2016','Medium: Polistireen velle','Grootte:<br/>450x350x400'],
    robobeast:['Titel:&nbsp;Radikaal Digitaal, 2016','Medium: Plastiek, verf','Grootte:<br/>230x450x230'],
    capital:['Titel:&nbsp;Kortbier, 2016','Medium: Bier','Grootte:<br/>270x280x200'],
  },

  preload:{
    images:['cat1_bg_1.png', 'cat1_bg_2.png','cat2_bg_1.png','cat2_bg_2.png','cat3_bg_1.png','cat3_bg_2.png','cat4_bg_1.png','cat4_bg_2.png','cat5_bg_1.png','catbg_capitalcraft.jpg',
          'navbg_capitalcraft.jpg'],
    loadingIndex: 0,
    readyOnIndex:2,
    isReady: false,
    allLoaded: false,
  },

  form:{
    open: false,
    api:{
      base: 'http://kreatiwiteitwiluit.co.za/aanbot/',
      getInfo: 'getHighest.php',
      offerSubmit: 'makeBet.php',
    },
    artworks:[],
    haveArtworkDetails: false,
    currentArtwork: null,
    offerResult: null,
    offerSubmitted: false,
  },
}

const store = createStore(rootReducer, defaultState, applyMiddleware(thunkMiddleware));
export const history = syncHistoryWithStore(browserHistory, store);
export default store;