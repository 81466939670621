import React from 'react';
import TweenMax from 'gsap';
import BtnComp from './BtnComp';
import RevealBlock from './Revealblock';
import RandomDots from './RandomDots';
import BGVideoComp from './BGVideoComp';

class RevealSlide extends React.Component{

    constructor(){
        super();
        const w = 15;
        const h = 10;
        this.numblocks = w*h;
    }

    componentDidMount(){
        this._btnOpenInfo.addEventListener('click',(event) => {
            this.props.openInfoPage(this.props.infoPageClass);
        })
    }

    renderBlocks(){
        let count = 0;
        let blocks = [];
        if(this.props.slideData.name === this.props.carousel.slides[this.props.carousel.currentSlide].name){
            while(count < this.numblocks){
                blocks.push(<RevealBlock {...this.props} key={count} />)
                count++
            }
            return <div className="reveal" ref={(r)=>{this._reveal = r}}>{blocks}</div>;
        }else{
            return null;
        }
    }

    renderVideo(){
        if(this.props.carousel.slides[this.props.carousel.currentSlide].name == this.props.slideData.name){
            return <BGVideoComp vidSrc={this.props.slideData.vidSrc} thisSlideIndex={this.getThisSlideIndex()} carousel={this.props.carousel} currentSlide={this.props.carousel.currentSlide} />
        }else{
            return null;
        }
    }

    getThisSlideIndex() {
        for(let i = 0; i <= this.props.carousel.slides.length; i++) {
            if (this.props.carousel.slides[i].name === this.props.slideClass) {
                return i;
            }
        }
    }

    renderInfoText(){
        if(this.props.slideData.introText){
            return <p>{this.props.slideData.introText}</p>
        }else{
            return null
        }
    }

    render(){
        return (
          <div className={`slide ${this.props.slideClass}`}>

            {this.renderVideo()}

            {this.renderBlocks()}
            <RandomDots />
            <div className="introTextHolder">{this.renderInfoText()}</div>
            <h2>{this.props.slideData.h2Txt}</h2>
            <div className="infoPageBtnCont" ref={(b)=>{this._btnOpenInfo = b}}>
              <BtnComp btnLabel={"Sien kunswerk"} />
            </div>
          </div>
        )
    }
}
export default RevealSlide;
            // <div className="close-cont-mask" ref="closeInfo"></div>