import fetch from 'isomorphic-fetch';

export const ActionTypes={
	GET_PAGES:'GET_PAGES',
  IS_FETCHING_PAGES: 'IS_FETCHING_PAGES',
  RECEIVE_PAGES:'RECEIVE_PAGES',
  FETCHING_PAGE_BY_SLUG:'FETCHING_PAGE_BY_SLUG',
  RECEIVE_PAGE_BY_SLUG:'RECEIVE_PAGE_BY_SLUG',

  OPEN_VIDEO_COMPONENT: 'OPEN_VIDEO_COMPONENT',
  CLOSE_VIDEO_COMPONENT: 'CLOSE_VIDEO_COMPONENT',
  CAROUSEL_DRAG: 'CAROUSEL_DRAG',
  CAROUSEL_DRAGGED: 'CAROUSEL_DRAGGED',
  SAVE_SLIDE_AUDIOTIME: 'SAVE_SLIDE_AUDIOTIME',
  CAROUSEL_AUDIO_ENDED: 'CAROUSEL_AUDIO_ENDED',
  OPEN_INFO_PAGE: 'OPEN_INFO_PAGE',
  CLOSE_INFO_PAGE: 'CLOSE_INFO_PAGE',  
  NAV_TO_SLIDE: 'NAV_TO_SLIDE',
  DID_NAV_TO_SLIDE: 'DID_NAV_TO_SLIDE',
  IMAGE_LOADED: 'IMAGE_LOADED',
  TOGGLE_SOUND: 'TOGGLE_SOUND',
  SHOW_HIDE_FORM: 'SHOW_HIDE_FORM',
  ARTWORK_DETAILS_RECEIVED: 'ARTWORK_DETAILS_RECEIVED',
  ARTWORK_OFFER_RESULT: 'ARTWORK_OFFER_RESULT',
  OFFER_RESET: 'OFFER_RESET',
}

function isFetchingPages(){
  return{
    type: ActionTypes.IS_FETCHING_PAGES
  }
}

function receivePages(data){
  return {
    type: ActionTypes.RECEIVE_PAGES,
    data
  }
}

function fetchingPage(slug){
  return{
    type: ActionTypes.FETCHING_PAGE_BY_SLUG,
    slug
  }
}

function receivePageData(slug, data){
  return{
    type: ActionTypes.RECEIVE_PAGE_BY_SLUG,
    slug,
    data
  }
}

export function openVideoComp(vidSrc) {
  return {
    type: ActionTypes.OPEN_VIDEO_COMPONENT,
    vidSrc
  }
}

export function closeVideoComp() {
  return {
    type: ActionTypes.CLOSE_VIDEO_COMPONENT
  }
}

export function carouselDrag(){
  return{
    type: ActionTypes.CAROUSEL_DRAG
  }
}

export function carouselDragged(currentSlide){
  return{
    type: ActionTypes.CAROUSEL_DRAGGED,
    currentSlide
  }
}

export function carouselSaveAudiotime(currentSlide, audioTime){
  return{
    type: ActionTypes.SAVE_SLIDE_AUDIOTIME,
    currentSlide,
    audioTime
  }
}
export function carouselAudioEnded(currentSlide){
  return{
    type: ActionTypes.CAROUSEL_AUDIO_ENDED,
    currentSlide
  }
}

export function openInfoPage(infoPageClass) {
  return {
    type: ActionTypes.OPEN_INFO_PAGE,
    infoPageClass
  }
}

export function closeInfoPage(infoPageClass) {
  return {
    type: ActionTypes.CLOSE_INFO_PAGE,
    infoPageClass
  }
}

export function navToSlide(navToIndex) {
  return {
    type: ActionTypes.NAV_TO_SLIDE,
    navToIndex
  }
}

export function didNavToSlide() {
  return {
    type: ActionTypes.DID_NAV_TO_SLIDE,
  }
}

export function toggleSound(){
  return {
    type: ActionTypes.TOGGLE_SOUND
  }
}


// PRELOADING IMAGES
function imageLoaded(index){
  return{
    type:ActionTypes.IMAGE_LOADED,
    index
  }
}

export function loadImages(){
  return function (dispatch, getState){

    const currentState = getState().preload;
    const images = currentState.images;
    if(currentState.loadingIndex < images.length){
      loadImage(images[currentState.loadingIndex], (success)=>{
        if(success){
          dispatch (imageLoaded(currentState.loadingIndex))
        }else{
          console.log('actions > loadImage > image not loaded: ', images[currentState.loadingIndex]);
        }
      })
    }else{
      console.log('actions > loadImages > stopped loading')
    }
    
  }
}

function loadImage(url, callback) {
    const src = 'images/' + url;
    const img = new Image();
    img.src = src;
    if (img.complete) { // If the image has been cached, just call the callback
      if (callback) callback(true);
    } else {
        img.onerror = function (err) { // If fail to load the image
            if (callback) callback(false);
        };
        img.onload = function () { // If loaded successfully
            img.onload = null;
            if (callback) callback(true);
        };
    };
} // end loadImg;


// --------------------------------------------------------------------------- FORM ACTIONS
export function showHideForm(show, artworkID=null){
  return {
    type:ActionTypes.SHOW_HIDE_FORM,
    show,
    artworkID
  }
}

export function getArtworkDetails(){
  return function(dispatch, getState){
    const api = getState().form.api;
    const url = api.base + api.getInfo;
    return fetch(url)
    .then((details) => {
      details.json().then(json => {
          dispatch(artworkDetailsReceived(json));
        })
    })
  }
}

function artworkDetailsReceived(details){
  return {
    type: ActionTypes.ARTWORK_DETAILS_RECEIVED,
    details
  }
}

export function makeOffer(data){
  return function (dispatch, getState){
    const api = getState().form.api;
    const url = api.base + api.offerSubmit;

    // only submit once
    if(!getState().form.offerSubmitted){
      return fetch(url,{
        method: 'post',
        body: data,
      })
      .then((res) => res.json())
      .then(json => {
        console.log('result: ', json);
          dispatch(artworkOfferResult(json))
      })
    }
  }
}

function artworkOfferResult(result){
  return{
    type: ActionTypes.ARTWORK_OFFER_RESULT,
    result
  }
}

export function resetOffer(){
  return {
    type: ActionTypes.OFFER_RESET
  }
}