import React from 'react';

import BtnComp from './BtnComp'
import TweenMax from 'gsap';

class BidForm extends React.Component{

    constructor(){
        super();
        //
    }

    componentDidMount(){
      // get reserve price for each piece
      
      this._close.addEventListener('click', ()=>{
        this.props.showHideForm(false)
      })

      if(!this.props.form.open){
        TweenMax.set(this._formHolder, {left: '-100%', autoAlpha: 0})
      }
    }

    componentDidUpdate(){
      if(this.props.form.open){
        TweenMax.to(this._formHolder, 1, {left: '0', ease: Power4.easeInOut, autoAlpha:1})
      }else{
        TweenMax.to(this._formHolder, 1, {left: '-100%', ease: Power4.easeInOut, autoAlpha: 0})
      }
    }

    

    onSubmit(event){
      console.log('form submit')
      const data = new FormData(this._form)
      // for (var value of data.values()) {
      //    console.log(value); 
      // }
      this.props.makeOffer(data)
    }

    formatPrice(price){
      const p = price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      return p
    }

    renderForm(){
      if(this.props.form.open){

        let artwork=null
        this.props.form.artworks.map((a)=>{
          if (a.id === this.props.form.currentArtwork){
            artwork = a
          }
        })
        return (
          <div className="form-holder">
            <form name="bid-form" className="form" ref={(f)=>this._form = f}>
              <div className="reserveprice">Reserweprys: R{this.formatPrice(artwork.price)}</div> 
              <label >Volle naam en van</label>
              <input type="text" name="name" id="name" placeholder="" />
              <label >e-posadres</label>
              <input type="text" name="email" id="email" placeholder="" />
              <label >kontaknommer</label>
              <input type="text" name="contact" id="contact" placeholder="" />
              <label >Aanbod</label>
              <input type="text" name="betprice" id="betprice" placeholder="" />
              <input type="hidden" name="artwork_id" id="artwork_id" value={artwork.id} />
              <div className="submit"><BtnComp btnLabel={"Maak 'n aanbod"} ref={(b)=>{this._submit = b}} onClick={this.onSubmit.bind(this)} /></div>
              <div className="disclaimer"><p>Vrywaring: ETIKET Brand Design behou die reg voor om 'n bod toe te staan/af te keur op grond van eie diskresie. Na afloop van die bieëry sal geen korrespondensie hieroor gevoer word nie.</p></div>
            </form>
          </div>
        )
      }else{
        return null
      }
    }

    render(){

        return(
            <div className="form-holder" ref={(c)=>{this._formHolder = c}}>
              <div className="close-cont-mask" ref={(close)=>{this._close = close}}></div>
              {this.renderForm()}
            </div>
        )
    }
}
export default BidForm;