import 'babel-polyfill';

// react
import React from 'react';
import {render} from 'react-dom';
import { Router, Route , IndexRoute, browserHistory } from 'react-router';

// redux
import { Provider } from 'react-redux';
import store, {history} from './store';

// components
import App from './components/App';
import Home from './components/Home';

render(
	<Provider store={store}>
		<Router history={history}>
      <Route path="/" component={App}>
        <IndexRoute component={Home}></IndexRoute>
      </Route>
    </Router>
	</Provider>, 
	document.getElementById('react-root')
);