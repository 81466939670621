import React from 'react';
import TweenMax from 'gsap';
import 'gsap/src/uncompressed/plugins/CSSPlugin'

class MainNavComp extends React.Component{

    constructor(){
        super();
        this.isOpen = false;
    }

    componentDidMount(){
      const tl = new TimelineMax();
       this.refs.navToggleBtn.addEventListener('click',() => {
          this.toggleNavigation();
       })
       this.refs.closeNavPage.addEventListener('click',(event) => {
          tl.to(this.refs.navPage,0.5,{height:'0%'})
          .to('.navBtns_con',0.5,{height:9})
          .to('.navComponent',0.5,{height:71});
        })

      const navBtns = this.refs.navBtns_con.getElementsByClassName('navBtn');
      Array.from(navBtns).forEach((element) => {
        element.addEventListener('click', () => {
          
          //  Close info page if open
          if (this.props.infoPages.open === true) {
            this.props.closeInfoPage(this.props.infoPages.openPage);  
          }
          //  Close nav page if open
          if (this.isOpen === true) {
            this.refs.closeNavPage.click();
            this.props.closeVideoComp();
            setTimeout(() => {
              this.props.navToSlide(parseInt(element.dataset.slide));
            },500);
          }else {
            this.props.navToSlide(parseInt(element.dataset.slide));
            this.props.closeVideoComp();
          }
        });
        element.addEventListener('mouseover', () => {
          if (this.isOpen) {
            this.refs.catTitle.innerHTML = this.props.carouselSlides[parseInt(element.getAttribute('data-slide'))].h2Txt;
            this.refs.navPage.style.backgroundImage = 'url(images/'+element.dataset.bg+')';  
          }
        })
      });

    }

    toggleNavigation() {
      const tl = new TimelineMax();
      if (this.isOpen === false) {
        this.refs.catTitle.innerHTML = this.props.carouselSlides[this.props.currentSlideIndex].h2Txt;
        tl.to(this.refs.navPage,0.5,{height:'100%'})
        .to('.navComponent',0.5,{height:100})
        .to('.navBtns_con',0.5,{height:39});
        this.isOpen = true;
        this.props.closeInfoPage(this.props.infoPages.openPage);
        this.props.closeVideoComp();
      }else {
        tl.to(this.refs.navPage,0.5,{height:'0%'})
        .to('.navBtns_con',0.5,{height:9})
        .to('.navComponent',0.5,{height:71});
        this.isOpen = false;
      }
    }

    

    componentDidUpdate(){
      const navBtns = this.refs.navBtns_con.getElementsByClassName('navBtn');
      for(let i=0;i < navBtns.length; i++) {
        if (i === this.props.currentSlideIndex) {
          navBtns[i].classList.add('selected');
          this.refs.navPage.style.backgroundImage = 'url(images/'+navBtns[i].dataset.bg+')';
        }else {
          navBtns[i].classList.remove('selected');
        }
      }
    }

    render(){
        return (
          <div>
            <div className="navPage" ref="navPage">
              <h2 ref="catTitle">{this.props.carouselSlides[this.props.currentSlideIndex].h2Txt}</h2>
              <div className="close-cont-mask" ref="closeNavPage"></div>
            </div>
            <div className="navComponent">
              <div className="navBtns_con" ref="navBtns_con">
                <div className="navBtn selected" data-slide="0" data-bg="intro_bg.png"></div>
                <div className="navBtn" data-slide="1" data-bg="navbg-anton-en-lionel.jpg"></div>
                <div className="navBtn" data-slide="2" data-bg="navbg-streetwires.jpg"></div>
                <div className="navBtn" data-slide="3" data-bg="navbg-jacovandermerwe.jpg"></div>
                <div className="navBtn" data-slide="4" data-bg="navbg-impressed.jpg"></div>
                <div className="navBtn" data-slide="5" data-bg="navbg-hardus-koekemoer.jpg"></div>
                <div className="navBtn" data-slide="6" data-bg="navbg-poko.jpg"></div>
                <div className="navBtn" data-slide="7" data-bg="navbg-robobeast.jpg"></div>
                <div className="navBtn" data-slide="8" data-bg="navbg-karensteenkamp.jpg"></div>
                <div className="navBtn" data-slide="9" data-bg="navbg-capitalcraft.jpg"></div>
                <div className="navBtn" data-slide="10" data-bg="navbg-retief.jpg"></div>
                <div className="navBtn" data-slide="11" data-bg="navbg-blank.jpg"></div>
              </div>
              <p className="navTitle" ref="navToggleBtn">Kieslys</p>
            </div>
          </div>
        )
    }
}
export default MainNavComp;