import React from 'react';
import TweenMax from 'gsap';
import BtnComp from './BtnComp';
import RandomDots from './RandomDots';

class InfoPageComp extends React.Component{

    constructor(){
        super();
    }

    componentDidMount(){
        this.refs.closeInfo.addEventListener('click',(event) => {
            this.props.closeInfoPage(this.props.slideClass);
        })
    }

    shouldComponentUpdate(nextProps){
      return (nextProps.infoPages.openPage === this.props.slideClass)
    }

    componentDidUpdate(){
        if (this.props.infoPages.openPage === this.props.slideClass && this.props.infoPages.open === true) {
            TweenMax.to(this.refs.thisSlide,1,{top:'0%', ease: Power4.easeInOut});
            this.playVideo("PLAY");
        }else if (this.props.infoPages.openPage === this.props.slideClass && this.props.infoPages.open === false) {
            TweenMax.to(this.refs.thisSlide,1,{top:'-100%', ease: Power4.easeInOut});
            this.playVideo("PAUSE");
        }
    }

    playVideo(action) {
        if(this.props.slideData.vidSrc != '' && this.props.slideData.vidSrc != undefined) {
            if (action === "PLAY") {
                setTimeout(() => {
                    this.refs.video_tag.play();
                },1500);
            }else {
                this.refs.video_tag.pause();
            }
        }
    }

    createHTMLMarkup(string) {
      return {__html: string};
    }

    renderBGVideo(vidSrc) {
        if(vidSrc != '' && vidSrc != undefined) {
            return <video muted loop poster="" ref="video_tag" className="fullscreen-bg__video">
              <source src={`${vidSrc}.ogv`} type="video/ogv"/>
              <source src={`${vidSrc}.mp4`} type="video/mp4"/>
              <source src={`${vidSrc}.webm`} type="video/webm"/>
            </video>
        }else {
            return '';
        }
    }

    setInfoTags() {
        if ('infoTags' in this.props) {
            return <div>
                        <div className="tag-position title"><div className="tag-relative">
                            <p dangerouslySetInnerHTML={this.createHTMLMarkup(this.props.infoTags[0])} />
                            <div className="line-graphic"></div>
                        </div></div>
                        <div className="tag-position medium"><div className="tag-relative">
                            <p dangerouslySetInnerHTML={this.createHTMLMarkup(this.props.infoTags[1])} />
                            <div className="line-graphic"></div>
                        </div></div>

                        <div className="tag-position size"><div className="tag-relative">
                            <p dangerouslySetInnerHTML={this.createHTMLMarkup(this.props.infoTags[2])} />
                            <div className="line-graphic"></div>
                        </div></div>
                    </div>
        }
    }

    openForm(artworkID){
        this.props.showHideForm(true, artworkID)
    }

    formatPrice(price){
      const p = price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      console.log(p)
      return p
    }

    render(){
        // console.log(this.props.slideData)
        let artwork1 = null
        this.props.artworks.map((a)=>{
          if (a.id === this.props.slideData.artworkIDs[0]){
            artwork1 = a
          }
        })
        return (
          <div className={`slide ${this.props.slideClass}`} ref="thisSlide">

            {this.renderBGVideo(this.props.slideData.vidSrc)}

            <RandomDots />
            <h2>{this.props.slideData.h2Txt}</h2>
            <div className="close-cont-mask" ref="closeInfo"></div>
            <div className="makeofferBtnCont" ref="MakeOfferBtn">
              <p className="btn-caption">{`Reserweprys: R${this.formatPrice(artwork1.price)}`}</p>
              <BtnComp btnLabel={"Maak 'n aanbod"} onClick={this.openForm.bind(this, this.props.slideData.artworkIDs[0])} />
            </div>

            {this.setInfoTags()}
            

          </div>
        )
    }
}
export default InfoPageComp;
