import React from 'react';
import TweenMax from 'gsap';

class CircleDotsComp extends React.Component{

    constructor(){
        super();
        //
    }

    /*shouldComponentUpdate(nextProps){
      return (nextProps.currentSlideIndex !== this.props.currentSlideIndex)
    }*/

    componentDidUpdate() {
      // console.log("hello: "+this.props.slides[this.props.currentSlideIndex].circleDotsPos);
      let newPos = this.props.slides[this.props.currentSlideIndex].circleDotsPos;
      if (this.props.infoPages.open === true) {
        const ipage = this.props.infoPages.openPage.split(' ')[this.props.infoPages.openPage.split(' ').length-1];
        console.log(ipage);
        newPos = this.props.infoPages[ipage].circleDotsPos;
      }
      this.positionCircles(newPos);
    }

    positionCircles(newPos) {
      let tweenTo;
      let alpha;
      const scale = this.scaleCircles();
      const tl = new TimelineMax();
      if (newPos[0] === 'none') {
        tweenTo = [0,-7];
        alpha = 0;
      }else if (newPos[0] === "center") {
        tweenTo = [0,-7];
        alpha = 1;
      }else {
        tweenTo = newPos;
        alpha = 1;
      }
        console.log(newPos)
      
      tl.to(this.refs.dotCircle,0.5,{autoAlpha:0})
      .to(this.refs.insideCircle,0.5,{autoAlpha:0})
      .to(this.refs.dotCircle, 0.25, {left: tweenTo[0]+"%",top: tweenTo[1]+"%"})
      .to(this.refs.insideCircle, 0.25, {left: tweenTo[0]+"%",top: tweenTo[1]+"%"})
      .to(this.refs.dotCircle, 0.5, {autoAlpha: alpha})
      .to(this.refs.insideCircle, 0.5, {autoAlpha: alpha});
    }

    scaleCircles() {
      const winW = window.innerWidth;
      let perc = winW / 1920;
      TweenMax.set(this.refs.dotCircle,{scale:perc});
      TweenMax.set(this.refs.insideCircle,{scale:perc});
      return perc;
    }

    componentDidMount(){
      this.circle_init(36);
      const newPos = this.props.slides[this.props.currentSlideIndex].circleDotsPos;
      this.positionCircles(newPos);
    }

    circle_init(dotsAmount) {

      const dotCircle = this.refs.dotCircle;
      let dotDevice = document.createElement('ul');
      dotDevice = dotCircle.appendChild(dotDevice);
      dotDevice.classList.add('dots-container');

      const insideCircle = this.refs.insideCircle;
      let insideDevice = document.createElement('ul');
      insideDevice = insideCircle.appendChild(insideDevice);
      insideDevice.classList.add('inside_circle');

      let DotsObjs = new Array();
      for(let i = 0; i < dotsAmount; i++) {
        let dotItem = document.createElement('li');
        dotItem = dotDevice.appendChild(dotItem);
        let dotImg = document.createElement('img');
        dotImg = dotItem.appendChild(dotImg);
        dotImg.src = 'images/dot-img.svg';
        dotItem.animating = "NO";
        DotsObjs.push(dotItem);

        let circItem = document.createElement('li');
        circItem = insideDevice.appendChild(circItem);
        let circImg = document.createElement('img');
        circImg = circItem.appendChild(circImg);
        circImg.src = 'images/circle-img.svg';
        circItem.animating = "NO";
        DotsObjs.push(circItem);
      }

      const DOMS = {
        dotCircle: dotCircle,
        dotDevice: dotDevice,
        dots: DotsObjs,
        insideCircle: insideCircle,
        insideDevice:insideDevice
      }
      this.circleAnimate(DOMS);
      this.circleRotate(DOMS.dotDevice,DOMS.insideDevice);
    }

    circleRotate(dotD,insideD) {
      const tl = new TimelineMax({repeat:-1});
      tl.to(dotD, 280,{rotation:360, ease:Linear.easeNone})
      .to(insideD, 280,{rotation:-360, ease:Linear.easeNone},'-=280');
    }

    circleAnimate(DOMS) {
        let itemIndex = Math.floor((Math.random() * (DOMS.dots.length-1)) + 1);
        setTimeout(() => {
            let thisItem = DOMS.dots[itemIndex];
            if (thisItem.animating != "YES") {
                thisItem.animating = "YES";
                TweenMax.to(thisItem,3,{scale:2,autoAlpha:0.5, ease: Elastic.easeOut.config(1, 0.3),onComplete: function() {
                  TweenMax.to(thisItem,3,{scale:1,autoAlpha:1, ease: Elastic.easeOut.config(1, 0.3), onComplete: function() {
                    thisItem.animating = "NO";  
                  }});
                }});
            }else {
            }
            
            this.circleAnimate(DOMS);
        }, 250);
    }

    componentWillMount(){
      // this.props.fetchPageBySlugName('home');
    }

    render(){
        return (
          <div className="circleDotsWrapper">
            <div ref="dotCircle" id="dotCircle">
            </div>
            <div ref="insideCircle" id="insideCircle">
            </div>
          </div>
        )
    }
}
export default CircleDotsComp;