import React from 'react';
import Gsap from 'gsap';

class VideoComp extends React.Component{

    constructor(){
        super();
        //
    }

    componentDidMount(){
      this.refs.video_tag.addEventListener('click',() => {
        this.props.closeVideoComp();
      });
      // hide video tag
      Gsap.TweenMax.set(this.refs.video_tag,{autoAlpha:0});
    }

    shouldComponentUpdate(nextProps){
      return (nextProps.videoComp.open !== this.props.videoComp.open || nextProps.videoComp.vidSrc !== this.props.videoComp.vidSrc)
    }

    componentDidUpdate() {
      const vidSrc = this.refs.video_tag.getElementsByTagName('source');
      const ext=['.ogv','.mp4','.webm']
      if (this.props.videoComp.open === true) {
        // fokop is hier.
        for(var i = 0; i < vidSrc.length; i++) {
          let vSrc = vidSrc[i].src;
          let filename = vSrc.split(".")[vSrc.split(".").length-2];
          let fileExt = vSrc.split(".")[vSrc.split(".").length-1];
          // let newSrc = vSrc.replace(filename,this.props.videoComp.vidSrc);
          let newSrc = this.props.videoComp.vidSrc+"."+fileExt;
          vidSrc[i].src = '/'+newSrc;
          vidSrc[i].src = this.props.videoComp.vidSrc + ext[i];
        }


        Gsap.TweenMax.to(this.refs.videoCont,1,{top:'0%',delay:0.25,onComplete: () => {
          Gsap.TweenMax.to(this.refs.video_tag,0.25,{autoAlpha:1});
          this.refs.video_tag.load();
          this.refs.video_tag.play();
        }});

      }else if (this.props.videoComp.open === false) {
        // pause video
        this.refs.video_tag.pause();
        Gsap.TweenMax.to(this.refs.videoCont,1,{top:'-100%',onComplete: () => {
          Gsap.TweenMax.to(this.refs.video_tag,0.25,{autoAlpha:0});
        }});
      }
    }

    render(){
        return (
          <div className="component_container video-component" ref="videoCont">
            <video muted loop poster="" ref="video_tag" className="fullscreen-bg__video">
              <source src="assets/videos/etiket.ogv" type="video/ogv"/>
              <source src="assets/videos/etiket.mp4" type="video/mp4"/>
              <source src="assets/videos/etiket.webm" type="video/webm"/>
            </video>
          </div>
        )
    }
}
export default VideoComp;