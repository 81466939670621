import {ActionTypes} from '../actions/actions';

export function form (state={}, action){

  switch(action.type){
    case ActionTypes.SHOW_HIDE_FORM:
      return Object.assign ({}, state, {
        open: action.show,
        currentArtwork: action.artworkID,
      })

    case ActionTypes.ARTWORK_DETAILS_RECEIVED:
      return Object.assign ({}, state, {
        artworks: action.details,
        haveArtworkDetails: true,
      })

    case ActionTypes.ARTWORK_OFFER_SUBMIT:
      return Object.assign ({}, state, {
        offerSubmitted: true,
        offerResult: null,
      })

    case ActionTypes.ARTWORK_OFFER_RESULT:
      return Object.assign ({}, state, {
        offerSubmitted: false,
        offerResult: action.result,
        open: false,
      })

    case ActionTypes.OFFER_RESET:
      return Object.assign ({}, state, {
        offerSubmitted: false,
        offerResult: null,
        open: false,
      })

    default: 
      return state
  }
}