import {ActionTypes} from '../actions/actions';

export function carousel (state={}, action){

  switch(action.type){

    case ActionTypes.CAROUSEL_DRAG:
      return Object.assign({}, state, {
        dragging: true,
        dragged: false,
      })

    case ActionTypes.CAROUSEL_DRAGGED:
      return Object.assign({}, state, {
        dragging: false,
        dragged: true,
        currentSlide: action.currentSlide,
      })
   
    case ActionTypes.SAVE_SLIDE_AUDIOTIME:
      return Object.assign({}, state, {
        slides: [
            ...state.slides.slice(0, action.currentSlide),
            saveSlideAudioTime(state.slides[action.currentSlide], action),
            ...state.slides.slice(action.currentSlide+1)
          ]
      })

    case ActionTypes.CAROUSEL_AUDIO_ENDED:
      return Object.assign({}, state, {
        slides: [
            ...state.slides.slice(0, action.currentSlide),
            saveAudioEnded(state.slides[action.currentSlide], action),
            ...state.slides.slice(action.currentSlide+1)
          ]
      })

    case ActionTypes.NAV_TO_SLIDE:
      return Object.assign({}, state, {
        navTo: true,
        navToIndex: action.navToIndex, 
        currentSlide: action.navToIndex,
        dragged: true,
        dragging: true,
      })

    case ActionTypes.DID_NAV_TO_SLIDE:
      return Object.assign({}, state, {
        navTo: false,
        dragging: false,
      })

    case ActionTypes.TOGGLE_SOUND:
      return Object.assign({}, state, {
        soundOn: !state.soundOn,
      })

    default:
      return state;
  }
}


function saveSlideAudioTime(state, action){
  return Object.assign({}, state, {
          audioTime: action.audioTime,
        })
}

function saveAudioEnded(state, action){
  return Object.assign({}, state, {
          audioTime: 0,
          audioComplete: true,
        })
}
