import {combineReducers} from 'redux';

import { routerReducer } from 'react-router-redux';
import {carousel} from  './carousel';
import {videoComp} from  './videoComp';
import {infoPages} from './infoPages';
import {preload} from './preload';
import {form} from './form';
import {infoTags} from './infoTags';

const rootReducer = combineReducers({carousel, videoComp, infoPages, preload, form, infoTags, routing:routerReducer}); 

export default rootReducer;