import React from 'react';
import TweenMax from 'gsap';
import BtnComp from './BtnComp';

class ParralaxComp extends React.Component{

    constructor(){
        super();
        //
    }

    componentDidMount(){
        const winH = window.innerHeight;
        const winW = window.innerWidth;
        
        // (() => {
        //     document.onmousemove = (event) => {
        //         // http://stackoverflow.com/questions/7790725/javascript-track-mouse-position
        //         let dot, eventDoc, doc, body, pageX, pageY;

        //         event = event || window.event; // IE-ism
        //         const xpos1 = (event.pageX * 0.10)+(winW/2)-((winW/2)*0.10);
        //         const xpos2 = (event.pageX * 0.20)+(winW/2)-((winW/2)*0.20);
        //         const xpos3 = (event.pageX * 0.30)+(winW/2)-((winW/2)*0.30);
        //         const xpos4 = (event.pageX * 0.40)+(winW/2)-((winW/2)*0.40);
        //         this.refs.parraS00.style.left = xpos1+"px";
        //         this.refs.parraS01.style.left = xpos1+"px";
        //         this.refs.parraS02.style.left = xpos2+"px";
        //         this.refs.parraB01.style.left = xpos3+"px";
        //         this.refs.parraB02.style.left = xpos4+"px";
        //         const ypos1 = (event.pageY * 0.05)+(winH/2)-((winH/2)*0.05);
        //         const ypos2 = (event.pageY * 0.10)+(winH/2)-((winH/2)*0.10);
        //         const ypos3 = (event.pageY * 0.15)+(winH/2)-((winH/2)*0.15);
        //         const ypos4 = (event.pageY * 0.20)+(winH/2)-((winH/2)*0.20);
        //         this.refs.parraS00.style.top = ypos1+"px";
        //         this.refs.parraS01.style.top = ypos1+"px";
        //         this.refs.parraS02.style.top = ypos2+"px";
        //         this.refs.parraB01.style.top = ypos3+"px";
        //         this.refs.parraB02.style.top = ypos4+"px";

        //     }
        // })();

    }

    componentDidUpdate(){
    }

    componentWillMount(){
      // this.props.fetchPageBySlugName('home');
    }

    render(){
        return (
          <div className="slide parralax_comp">
            <img src="images/tmp/parra-small01.png" className="parra-layer parraS00" ref="parraS00"  />
            <img src="images/tmp/parra-small01.png" className="parra-layer parraS01" ref="parraS01"  />
            <img src="images/tmp/parra-small02.png" className="parra-layer parraS02" ref="parraS02"  />
            <img src="images/tmp/parra-big01.png" className="parra-layer parraB01" ref="parraB01"  />
            <img src="images/tmp/parra-big02.png" className="parra-layer parraB02" ref="parraB02"  />
            <h2>{this.props.slideData.h2Txt}</h2>
            <img src="images/dot-device.png" className="corner-dot-device"  />
            <div className="makeofferBtnCont" ref="MakeOfferBtn">
              <p className="btn-caption">Reserwe Prys: R40 000</p>
              <BtnComp btnLabel={"Maak 'n aanbod"}  />
            </div>
          </div>
        )
    }
}
export default ParralaxComp;