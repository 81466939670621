import React from 'react';
import TweenMax from 'gsap';
import BtnComp from './BtnComp';
import RandomDots from './RandomDots';

class ImpressedInfoPage extends React.Component{

    constructor(){
        super();
    }

    componentDidMount(){
        this.refs.closeInfo.addEventListener('click',(event) => {
            this.props.closeInfoPage(this.props.slideClass);
        })

        this.refs.btn_poster1.addEventListener('click',(event) => {
            this.props.openVideoComp("assets/videos/impressed-koerantadvertensies");
        })
        this.refs.btn_poster2.addEventListener('click',(event) => {
            this.props.openVideoComp("assets/videos/impressed-mag");
        })
        this.refs.btn_poster3.addEventListener('click',(event) => {
            this.props.openVideoComp("assets/videos/impressed-plakkate");
        })
    }

    shouldComponentUpdate(nextProps){
      return (nextProps.infoPages.openPage === this.props.slideClass)
    }

    componentDidUpdate(){
        if (this.props.infoPages.openPage === this.props.slideClass && this.props.infoPages.open === true) {
            TweenMax.to(this.refs.thisSlide,1,{top:'0%', ease: Power4.easeInOut});
        }else if (this.props.infoPages.openPage === this.props.slideClass && this.props.infoPages.open === false) {
            TweenMax.to(this.refs.thisSlide,1,{top:'-100%', ease: Power4.easeInOut});
        }
    }

    formatPrice(price){
      const p = price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      return p
    }

    openForm(artworkID){
        this.props.showHideForm(true, artworkID)
    }

    render(){
        let artwork1 = null
        this.props.artworks.map((a)=>{
          if (a.id === this.props.slideData.artworkIDs[0]){
            artwork1 = a
          }
        })
        let artwork2 = null
        this.props.artworks.map((a)=>{
          if (a.id === this.props.slideData.artworkIDs[1]){
            artwork2 = a
          }
        })
        let artwork3 = null
        this.props.artworks.map((a)=>{
          if (a.id === this.props.slideData.artworkIDs[2]){
            artwork3 = a
          }
        })
        return (
          <div className={`slide ${this.props.slideClass}`} ref="thisSlide">   
            <RandomDots />
            <h2>{this.props.slideData.h2Txt}</h2>

            <div className="close-cont-mask" ref="closeInfo"></div>
            
            <div className="posterCont poster1">
                <div className="suspenders">
                    <div className="line-graphic"></div>
                    <p>Titel: Koerantadvertensies<br/>Groote: 297x420</p>
                </div>
                <img src="images/impressed-poster1.jpg" className="impressed-poster-img" ref="btn_poster1" />

                <div className="bid_btncont">
                    <div className="makeofferBtnCont" ref="MakeOfferBtn">
                      <p className="btn-caption">{`Reserweprys: R${this.formatPrice(artwork1.price)}`}</p>
                      <BtnComp btnLabel={"Maak 'n aanbod"} onClick={this.openForm.bind(this, this.props.slideData.artworkIDs[0])}  />
                    </div>
                </div>
            </div>

            <div className="posterCont poster2">
                <div className="suspenders">
                    <div className="line-graphic"></div>
                    <p>Titel: Tydskrifadvertensies,<br/> 2016</p>
                </div>
                <img src="images/impressed-poster2.jpg" className="impressed-poster-img" ref="btn_poster2" />

                <div className="bid_btncont">
                    <div className="makeofferBtnCont" ref="MakeOfferBtn">
                      <p className="btn-caption">{`Reserwe Prys: R${this.formatPrice(artwork2.price)}`}</p>
                      <BtnComp btnLabel={"Maak 'n aanbod"} onClick={this.openForm.bind(this, this.props.slideData.artworkIDs[1])}  />
                    </div>
                </div>
            </div>

            <div className="posterCont poster3">
                <div className="suspenders">
                    <div className="line-graphic"></div>
                    <p>Titel: Plakkate<br/>Medium: Ink, papier</p>
                </div>
                <img src="images/impressed-poster3.jpg" className="impressed-poster-img" ref="btn_poster3" />

                <div className="bid_btncont">
                    <div className="makeofferBtnCont" ref="MakeOfferBtn">
                      <p className="btn-caption">{`Reserweprys: R${this.formatPrice(artwork3.price)}`}</p>
                      <BtnComp btnLabel={"Maak 'n aanbod"} onClick={this.openForm.bind(this, this.props.slideData.artworkIDs[2])}  />
                    </div>
                </div>
            </div>
          </div>
        )
    }
}
export default ImpressedInfoPage;