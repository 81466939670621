import React from 'react';

class SoundControl extends React.Component{

    constructor(){
        super();
        //
    }

    componentDidMount(){
      this._button.addEventListener('click', (event)=>{
        this.props.toggleSound();
      })
    }

    renderbutton(){
      if(this.props.carousel.soundOn){
        return (
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22"><g transform="matrix(.1615 0 0 .1615 1.7 1.638)"  className="sound-icon"><path d="m57.57 8.464c-1.412 0-2.634.517-3.666 1.548l-27.13 27.13h-21.346c-1.413 0-2.635.517-3.667 1.548-1.032 1.032-1.548 2.255-1.548 3.667v31.28c0 1.412.516 2.635 1.548 3.666 1.033 1.032 2.255 1.549 3.667 1.549h21.345l27.13 27.13c1.032 1.031 2.254 1.548 3.666 1.548 1.413 0 2.635-.517 3.667-1.548 1.032-1.031 1.548-2.254 1.548-3.666v-88.64c0-1.412-.516-2.635-1.547-3.667-1.033-1.031-2.254-1.548-3.667-1.548"/><path d="m90.61 69.53c2.309-3.557 3.463-7.399 3.463-11.527 0-4.128-1.154-7.988-3.463-11.573-2.31-3.585-5.363-6.11-9.166-7.577-.543-.271-1.223-.407-2.037-.407-1.412 0-2.635.503-3.666 1.507-1.031 1.01-1.549 2.241-1.549 3.707 0 1.141.326 2.105.979 2.893.652.788 1.439 1.467 2.363 2.037.922.57 1.846 1.195 2.77 1.874.924.679 1.711 1.643 2.363 2.892.65 1.25.977 2.798.977 4.645 0 1.847-.326 3.395-.977 4.645-.652 1.25-1.439 2.213-2.363 2.893-.924.679-1.848 1.304-2.77 1.874-.924.569-1.711 1.249-2.363 2.036-.652.789-.979 1.752-.979 2.893 0 1.467.518 2.702 1.549 3.707 1.031 1 2.254 1.508 3.666 1.508.814 0 1.494-.136 2.037-.406 3.803-1.523 6.857-4.063 9.166-7.621"/><path d="m108 81.02c4.617-7.03 6.926-14.705 6.926-23.02 0-8.311-2.309-15.982-6.926-23.02-4.617-7.03-10.729-12.152-18.332-15.357-.705-.271-1.412-.407-2.117-.407-1.412 0-2.635.516-3.668 1.548-1.031 1.032-1.547 2.254-1.547 3.666 0 2.119 1.059 3.721 3.178 4.808 3.041 1.575 5.105 2.77 6.191 3.584 4.02 2.934 7.156 6.613 9.41 11.04 2.254 4.427 3.381 9.139 3.381 14.14 0 4.997-1.127 9.709-3.381 14.14-2.254 4.428-5.391 8.106-9.41 11.04-1.086.815-3.15 2.01-6.191 3.585-2.119 1.087-3.178 2.689-3.178 4.808 0 1.412.516 2.635 1.547 3.666 1.033 1.033 2.281 1.549 3.748 1.549.652 0 1.332-.137 2.037-.407 7.603-3.206 13.715-8.325 18.332-15.359"/></g></svg>
          )
      }else{
        return (
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22"><g transform="matrix(.1615 0 0 .1615 1.91 1.638)"  className="sound-icon"><path d="m57.57 8.464c-1.412 0-2.634.517-3.666 1.548l-27.13 27.13h-21.346c-1.413 0-2.635.517-3.667 1.548-1.032 1.032-1.548 2.255-1.548 3.667v31.28c0 1.412.516 2.635 1.548 3.666 1.033 1.032 2.255 1.549 3.667 1.549h21.345l27.13 27.13c1.032 1.031 2.254 1.548 3.666 1.548 1.413 0 2.635-.517 3.667-1.548 1.032-1.031 1.548-2.254 1.548-3.666v-88.64c0-1.412-.516-2.635-1.547-3.667-1.033-1.031-2.254-1.548-3.667-1.548"/><path d="m68.989 48l23.953-23.953c1.521-1.52 2.281-3.367 2.281-5.54 0-2.173-.761-4.02-2.281-5.54l-11.08-11.08c-1.521-1.521-3.367-2.281-5.539-2.281-2.174 0-4.02.76-5.541 2.281l-23.953 23.953-23.953-23.953c-1.52-1.521-3.367-2.281-5.54-2.281-2.172 0-4.02.76-5.54 2.281l-11.08 11.08c-1.52 1.52-2.281 3.367-2.281 5.54 0 2.173.76 4.02 2.281 5.54l23.953 23.953-23.953 23.953c-1.52 1.521-2.281 3.367-2.281 5.54 0 2.173.76 4.02 2.281 5.54l11.08 11.08c1.521 1.521 3.368 2.281 5.54 2.281 2.173 0 4.02-.761 5.54-2.281l23.953-23.953 23.954 23.953c1.521 1.521 3.366 2.281 5.541 2.281 2.172 0 4.02-.761 5.539-2.281l11.08-11.08c1.521-1.52 2.281-3.367 2.281-5.54 0-2.173-.76-4.02-2.281-5.54l-23.953-23.953" transform="matrix(.45902 0 0 .45902 68.7 38.09)"/></g></svg>
          )
      }
    }

    render(){

        return(
            <div className="sound-control" ref={(b)=>{this._button = b}}>
              {this.renderbutton()}
            </div>
        )
    }
}
export default SoundControl;