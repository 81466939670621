import React from 'react';

class BackgroundAudio extends React.Component{

    constructor(){
        super();
        //
    }

    componentDidMount(){
      this._audio.volume = 0.3;
      this._audio.play()
    }
    
    shouldComponentUpdate(){
      return false
    }

    render(){

        return (
          <audio loop ref={(a)=>{this._audio = a}}>
            <source src={this.props.audioSource} type="audio/mpeg" />
          </audio>
        )
    }
}
export default BackgroundAudio;