import React from 'react';
import TweenMax from 'gsap';
import BtnComp from './BtnComp';
import RandomDots from './RandomDots';

class SpyGlassComp extends React.Component{

    constructor(){
        super();
        //
    }

    componentDidMount(){
        const winH = window.innerHeight;
        const winW = window.innerWidth;
        const intContW = 1238;
        const intContH = 769;
        
        (() => {
            document.onmousemove = (event) => {
                // http://stackoverflow.com/questions/7790725/javascript-track-mouse-position
                let dot, eventDoc, doc, body, pageX, pageY;

                event = event || window.event; // IE-ism
                // console.log(event.pageX,event.pageY);
                // If pageX/Y aren't available and clientX/Y are,
                // calculate pageX/Y - logic taken from jQuery.
                // (This is to support old IE)
                /*
                if (event.pageX == null && event.clientX != null) {
                    eventDoc = (event.target && event.target.ownerDocument) || document;
                    doc = eventDoc.documentElement;
                    body = eventDoc.body;

                    event.pageX = event.clientX +
                      (doc && doc.scrollLeft || body && body.scrollLeft || 0) -
                      (doc && doc.clientLeft || body && body.clientLeft || 0);
                    event.pageY = event.clientY +
                      (doc && doc.scrollTop  || body && body.scrollTop  || 0) -
                      (doc && doc.clientTop  || body && body.clientTop  || 0 );
                }
                */
                // Use event.pageX / event.pageY here
                
                const xpos1 = (event.pageX * 0.75)+(winW/2)-((winW/2)*0.75);
                const xpos2 = (event.pageX * 0.80)+(winW/2)-((winW/2)*0.80);
                const xpos3 = (event.pageX * 0.85)+(winW/2)-((winW/2)*0.85);
                this.refs.circle1.style.left = xpos1+"px";
                this.refs.circle2.style.left = xpos2+"px";
                this.refs.circle3.style.left = xpos3+"px";
                const ypos1 = (event.pageY * 0.75)+(winH/2)-((winH/2)*0.75);
                const ypos2 = (event.pageY * 0.80)+(winH/2)-((winH/2)*0.80);
                const ypos3 = (event.pageY * 0.85)+(winH/2)-((winH/2)*0.85);
                this.refs.circle1.style.top = ypos1+"px";
                this.refs.circle2.style.top = ypos2+"px";
                this.refs.circle3.style.top = ypos3+"px";

                const clipX = xpos2 - ((winW - intContW)/2);
                const clipY = ypos2 - ((winH - intContH)/2);
                this.refs.clipCircle.setAttribute("cx", clipX);
                this.refs.clipCircle.setAttribute("cy", clipY);

                const infoX = xpos1 - ((winW - intContW)/2) - 400;
                const infoY = ypos1 - ((winH - intContH)/2) - 300;
                this.refs.artInfoCont.style.left = infoX+"px";
                this.refs.artInfoCont.style.top = infoY+"px";
            }
        })();

/*        this.refs.hotspot1.addEventListener('mouseover',() => {
            this.showInfo("SHOW");
        })
        this.refs.hotspot2.addEventListener('mouseover',() => {
            this.showInfo("SHOW");
        })
        this.refs.hotspot3.addEventListener('mouseover',() => {
            this.showInfo("SHOW");
        })
        this.refs.hotspot4.addEventListener('mouseover',() => {
            this.showInfo("SHOW");
        })
        this.refs.hotspot5.addEventListener('mouseover',() => {
            this.showInfo("SHOW");
        })
        this.refs.hotspot6.addEventListener('mouseover',() => {
            this.showInfo("SHOW");
        })*/
        this.refs.hotspot7.addEventListener('mouseover',() => {
            this.showInfo("SHOW");
        })

/*        this.refs.hotspot1.addEventListener('mouseout',() => {
          this.showInfo("HIDE");
        })
        this.refs.hotspot2.addEventListener('mouseout',() => {
          this.showInfo("HIDE");  
        })
        this.refs.hotspot3.addEventListener('mouseout',() => {
            this.showInfo("HIDE");
        })
        this.refs.hotspot4.addEventListener('mouseout',() => {
          this.showInfo("HIDE");
        })
        this.refs.hotspot5.addEventListener('mouseout',() => {
          this.showInfo("HIDE");  
        })
        this.refs.hotspot6.addEventListener('mouseout',() => {
            this.showInfo("HIDE");
        })*/
        this.refs.hotspot7.addEventListener('mouseout',() => {
            this.showInfo("HIDE");
        })
        this.refs.hotspot7.addEventListener('click',(event) => {
            // still seems to be bit buggy if you drag while mouse is over the hostspot:
            if(!this.props.carousel.dragging){
                this.props.openVideoComp(this.props.slideData.vidSrc);
            }
        });
    }

    showInfo(action) {
        const tl = new TimelineMax();
        if (action === "SHOW") {
            tl.to(this.refs.infoTitle,0.25,{autoAlpha:1})
            .to(this.refs.infoMedium,0.25,{autoAlpha:1})
            .to(this.refs.infoSize,0.25,{autoAlpha:1});
        }else {
            tl.to(this.refs.infoTitle,0.25,{autoAlpha:0})
            .to(this.refs.infoMedium,0.25,{autoAlpha:0})
            .to(this.refs.infoSize,0.25,{autoAlpha:0});  
        }
    }

    componentDidUpdate(){
    }

    componentWillMount(){
      // this.props.fetchPageBySlugName('home');
    }

    formatPrice(price){
      const p = price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      console.log(p)
      return p
    }


    openForm(artworkID){
        this.props.showHideForm(true, artworkID)
    }
    
    render(){

        let artwork1 = null
        this.props.artworks.map((a)=>{
          if (a.id === this.props.slideData.artworkIDs[0]){
            artwork1 = a
          }
        })
        return (
          <div className="slide spyglass_comp">
            <div className="interactive_container">

                <svg x="0px" y="0px" width="1238px" height="769px" viewBox="0 0 1238 769">
                    <g>
                        <defs>
                            <circle id="clipCircle" ref="clipCircle" cx="619" cy="384.5" r="230"/>
                        </defs>
                        <clipPath id="clipPath" ref="clipPath">
                            <use xlinkHref="#clipCircle"/>
                        </clipPath>
                        <image width="1238" height="769" id="clipImage" xlinkHref="images/Hardus-maskingIMg.jpg" ></image>
                    </g>
                </svg>

                
                <div className="hotspot_box hotspot7" ref="hotspot7"></div>

                <div className="artInfoCont" ref="artInfoCont">
                    <p className="infoText info-title" ref="infoTitle">Titel: Liedboek, 2016 </p>
                    <p className="infoText info-medium" ref="infoMedium">Meduim: Olie </p>
                    <p className="infoText info-size" ref="infoSize">Grootte: 150x100cm<br/>(geraam)</p>
                </div>


            </div>

            <img src="images/spyglass-circles-01.svg" className="spyglass_circle" ref="circle1"  />
            <img src="images/spyglass-circles-02.svg" className="spyglass_circle" ref="circle2"  />
            <img src="images/spyglass-circles-03.svg" className="spyglass_circle" ref="circle3"  />
            <h2>{this.props.slideData.h2Txt}</h2>
            <RandomDots />
            <div className="makeofferBtnCont" ref="MakeOfferBtn">
              <p className="btn-caption">{`Reserweprys: R${this.formatPrice(artwork1.price)}`}</p>
              <BtnComp btnLabel={"Maak 'n aanbod"} onClick={this.openForm.bind(this, this.props.slideData.artworkIDs[0])}   />
            </div>
          </div>
        )
    }
}
export default SpyGlassComp;