import React from 'react';
import TweenMax from 'gsap';
import BtnComp from './BtnComp';
import RandomDots from './RandomDots';


class SlideGrid1 extends React.Component{

    constructor(){
        super();
    }

    componentDidMount() {
      this.refs.videoBox.addEventListener('click',(event) => {
        this.props.openVideoComp(this.props.slideData.vidSrc);
      });
      this.refs.btnOpenInfo.addEventListener('click',(event) => {
        this.props.openInfoPage(this.props.infoPageClass);
      })
      setTimeout(() => {
        this.refs.boxVideoTag.play();  
      },500);
    }

    componentWillUpdate()
    {
      // this.props.carousel
      if(this.props.carousel.dragging){
        if(this.refs.boxVideoTag){
          this.refs.boxVideoTag.pause();
        }
      }
    }

    componentDidUpdate(){
      if(this.props.carousel.slides[this.props.carousel.currentSlide].name == this.props.slideData.name){
        if(this.props.carousel.dragging){
          if(this.refs.boxVideoTag){
            this.refs.boxVideoTag.pause();
          }
        }else{
          if(this.refs.boxVideoTag){
            this.refs.boxVideoTag.play();
          }
        }
      }
    }

    render(){
        return (
          <div className={`slide ${this.props.slideClass}`}>
            <div className="grid-layout grid-top">
              <div className="grid-box left-box box1"></div>
              <div className="grid-box right-box box2" ref="videoBox">
                <video muted loop poster="" className="fullscreen-bg__video" ref="boxVideoTag">
                  <source src="assets/videos/lionel-en-anton-smit.ogv" type="video/ogv"/>
                  <source src="assets/videos/lionel-en-anton-smit.mp4" type="video/mp4"/>
                  <source src="assets/videos/lionel-en-anton-smit.webm" type="video/webm"/>
                </video>
              </div>
            </div>

            <div className="grid-layout grid-bottom">
              <div className="grid-box left-box box1">

              </div>
              <div className="grid-box left-box box2">

                <p className="btn_info als">Pa en seun, Anton en Lionel Smit, is kop in een mus. Hul kunswerke kan onmiddellik uitgeken word aan die indrukwekkende skaal daarvan. </p>
                <hr/>


                <div className="infoPageBtnCont" ref="btnOpenInfo">
                  <BtnComp btnLabel={"Sien kunswerk"} />
                </div>

              </div>
              <div className="grid-box right-box box3"></div>
            </div>
            <RandomDots />
            <h2>{this.props.slideData.h2Txt}</h2>
          </div>
        )
    }
}
export default SlideGrid1;