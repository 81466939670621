import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

import * as actions from '../actions/actions';

import Main from './Main';

function mapStateToProps (state){
	return {
		carousel: state.carousel,
		infoPages: state.infoPages,
		videoComp: state.videoComp,
    preload: state.preload,
		form: state.form,
		infoTags: state.infoTags,
	}
}

function mapDispatchToProps (dispatch){
	return bindActionCreators(actions, dispatch);
}

const App = connect (mapStateToProps, mapDispatchToProps)(Main);

export default App;