import React from 'react';
import TweenMax from 'gsap';

class RandomDots extends React.Component{

    constructor(){
        super();
        //
    }

    componentDidMount(){
      const t = 1
      const tl = new TimelineMax({repeat: -1})
      tl.to(this._i1, t, {autoAlpha: 0, ease: Power4.easeInOut, delay: t})
        .fromTo(this._i2, t, {autoAlpha: 0}, {autoAlpha:1, ease: Power4.easeInOut}, `-=${t}`)
        
        .to(this._i2, t, {autoAlpha: 0, ease:Power4.easeInOut, delay: t})
        .fromTo(this._i3, t, {autoAlpha: 0}, {autoAlpha:1, ease: Power4.easeInOut}, `-=${t}`)

        .to(this._i3, t, {autoAlpha: 0, ease:Power4.easeInOut, delay: t})
        .fromTo(this._i4, t, {autoAlpha: 0}, {autoAlpha:1, ease: Power4.easeInOut}, `-=${t}`)

        .to(this._i4, t, {autoAlpha: 0, ease:Power4.easeInOut, delay: t})
        .fromTo(this._i5, t, {autoAlpha: 0}, {autoAlpha:1, ease: Power4.easeInOut}, `-=${t}`)

        .to(this._i5, t, {autoAlpha: 0, ease:Power4.easeInOut, delay: t})
        .fromTo(this._i6, t, {autoAlpha: 0}, {autoAlpha:1, ease: Power4.easeInOut}, `-=${t}`)

        .to(this._i6, t, {autoAlpha: 0, ease:Power4.easeInOut, delay: t})
        .fromTo(this._i7, t, {autoAlpha: 0}, {autoAlpha:1, ease: Power4.easeInOut}, `-=${t}`)

        .to(this._i7, t, {autoAlpha: 0, ease:Power4.easeInOut, delay: t})
        .fromTo(this._i8, t, {autoAlpha: 0}, {autoAlpha:1, ease: Power4.easeInOut}, `-=${t}`)

        .to(this._i8, t, {autoAlpha: 0, ease:Power4.easeInOut, delay: t})
        .to(this._i1, t, {autoAlpha:1, ease: Power4.easeInOut}, `-=${t}`)
    }

    shouldComponentUpdate(){
      return false
    }

    renderDevice(){
      const imgStyle={
        position: 'absolute',
        top: 0,
      }
      // return <img src="images/dot-device.png" className="corner-dot-device" style={thestyle} />
      return (
        <div className="corner-dot-device">
          <img src="images/1.png" style={imgStyle}ref={(i)=>{this._i1 = i}}/>
          <img src="images/2.png" style={imgStyle}ref={(i)=>{this._i2 = i}}/>
          <img src="images/3.png" style={imgStyle}ref={(i)=>{this._i3 = i}}/>
          <img src="images/4.png" style={imgStyle}ref={(i)=>{this._i4 = i}}/>
          <img src="images/5.png" style={imgStyle}ref={(i)=>{this._i5 = i}}/>
          <img src="images/6.png" style={imgStyle}ref={(i)=>{this._i6 = i}}/>
          <img src="images/7.png" style={imgStyle}ref={(i)=>{this._i7 = i}}/>
          <img src="images/8.png" style={imgStyle}ref={(i)=>{this._i8 = i}}/>
        </div>
      )
    }

    render(){

        return(
            <div>{this.renderDevice()}</div>
        )
    }
}
export default RandomDots;