import React from 'react';
import TweenMax from 'gsap';
import BtnComp from './BtnComp';
import BGVideoComp from './BGVideoComp';
import RandomDots from './RandomDots';


class Slide extends React.Component{

    constructor(){
        super();
        //
    }

    getThisSlideIndex() {
        for(let i = 0; i <= this.props.carousel.slides.length; i++) {
            if (this.props.carousel.slides[i].name === this.props.slideClass) {
                return i;
            }
        }
    }

    componentDidMount(){
        this.refs.btnOpenInfo.addEventListener('click',(event) => {
            this.props.openInfoPage(this.props.infoPageClass);
        })
    }

    createHTMLMarkup(string) {
      return {__html: string};
    }

    shouldComponentUpdate(nextProps){
        if(nextProps.slideClass === this.props.slideClass) {
            return true;
        }else {
            return false;      
        }
    }

    componentDidUpdate() {
        
    }

    setInfoTags() {
        if ('infoTags' in this.props) {
            return <div>
                    <div className="tag-position title"><div className="tag-relative">
                        <p dangerouslySetInnerHTML={this.createHTMLMarkup(this.props.infoTags[0])} />
                        <div className="line-graphic"></div>
                    </div></div>
                    <div className="tag-position medium"><div className="tag-relative">
                        <p dangerouslySetInnerHTML={this.createHTMLMarkup(this.props.infoTags[1])} />
                        <div className="line-graphic"></div>
                    </div></div>

                    <div className="tag-position size"><div className="tag-relative">
                        <p dangerouslySetInnerHTML={this.createHTMLMarkup(this.props.infoTags[2])} />
                        <div className="line-graphic"></div>
                    </div></div>
                </div>
        }
    }

    renderInfoText(){
        if(this.props.slideData.introText){
            return <p>{this.props.slideData.introText}</p>
        }else{
            return null
        }
    }
    renderLogo(){
        if(this.props.slideData.logo){
            return <img src="images/pendoring.png" className="logo"/>
        }else{
            return null
        }
    }

    renderVideo(){
        if(this.props.carousel.slides[this.props.carousel.currentSlide].name == this.props.slideData.name){
            return <BGVideoComp vidSrc={this.props.slideData.vidSrc} thisSlideIndex={this.getThisSlideIndex()} carousel={this.props.carousel} currentSlide={this.props.carousel.currentSlide} />
        }else{
            return null;
        }
    }


    render(){
        return (
          <div className={`slide ${this.props.slideClass}`} ref="thisSlide">

            {this.renderVideo()}

            <RandomDots />
            <h2>{this.props.slideData.h2Txt}</h2>

            <div className="infoPageBtnCont" ref="btnOpenInfo">
              <BtnComp btnLabel={"Sien kunswerk"} />
            </div>

            <div className="introTextHolder">{this.renderInfoText()}</div>
            <div className="logoHolder">{this.renderLogo()}</div>

            {this.setInfoTags()}

          </div>
        )
    }
}
export default Slide;
            // <div className="close-cont-mask" ref="closeInfo"></div>