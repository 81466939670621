import React from 'react';
import TweenMax from 'gsap';

class BtnComp extends React.Component{

    constructor(){
        super();
        //
    }

    componentDidMount(){
        //  button stuffs
        this.refs.btnCont.addEventListener('mouseover',(event) => {
            this.animateBtn(event.target,"over");
        })
        this.refs.btnCont.addEventListener('mouseout',(event) => {
            this.animateBtn(event.target,"out");
        })
        this.refs.btnCont.addEventListener('click',(event) => {
          if(this.props.onClick){
            this.props.onClick(event);
          }
        })
    }

    animateBtn(targetBtn,mouse) {
      const sideline = targetBtn.getElementsByClassName('sideLineRight');  
      const botline = targetBtn.getElementsByClassName('botLineLeft');
      const tl = new TimelineMax();
      if (mouse === "over") {
        tl.to(sideline,0.5,{top:'50%'})
        .to(botline,0.5,{left:'75%'},'-=0.5');
      }else {
        tl.to(sideline,0.25,{top:'0%'})
        .to(botline,0.25,{left:'0%'},'-=0.25');
      }
    }

    render(){
        return (
          <div className="anim-btn gold" ref="btnCont"><p>{this.props.btnLabel}</p>
            <div className="sideLineRight"></div>
            <div className="botLineLeft"></div>
          </div>
        )
    }
}
export default BtnComp;