import React from 'react';
import {owlCarousel} from 'owl.carousel'; // https://owlcarousel2.github.io/OwlCarousel2/
import TweenMax from 'gsap';

class Owl extends React.Component{

    constructor(){
        super();
    }

    componentDidMount(){
      const defaultoptions={
        items: 1,
      }

      let options = Object.assign({}, defaultoptions, this.props.options);
      this.owl = $(this._owl).owlCarousel(options);
    }

    gotoSlide(slideIndex){
      this.owl.trigger('to.owl.carousel',[parseInt(slideIndex)])
    }

    componentDidUpdate(){
      if (this.props.videoComp.open || this.props.infoPages.open) {
        TweenMax.to(this._owl, 1, {top:"100%", ease: Power4.easeInOut});
      }else{
        TweenMax.to(this._owl, 1, {top:"0%", ease: Power4.easeInOut}); 
      }
    }

    render(){
        var childrenWithProps = React.Children.map(this.props.children, (child) => {
            return React.cloneElement(child, { carousel: this.props.carousel });
        });

        return <div ref={(o) => this._owl = o} className="owl-carousel">{childrenWithProps}</div>
    }


}
export default Owl;
