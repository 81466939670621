import React from 'react';
import TweenMax from 'gsap'

class RevealBlock extends React.Component{

    constructor(){
        super();
    }

    componentDidMount(){
      const _w = window.innerWidth / 15;
      const _h = window.innerHeight / 10;
      TweenMax.set(this.block, {width: _w+'px', height:_h+'px'});

      this.tl = new TimelineMax()

      this.doEventListeners()


    }

    shouldComponentUpdate(nextProps){
        return (this.props.slideData.name === nextProps.carousel.slides[nextProps.carousel.currentSlide].name)
    }
    
    componentWillUpdate(){
      if (this.block){
       TweenMax.set(this.block, {autoAlpha: 1})
      }

    }

    componentDidUpdate(){
      TweenMax.delayedCall(2, this.doEventListeners.bind(this))
      this.tl = new TimelineMax();

      // TweenMax.killDelayedCallsTo(this.showAll);
      // TweenMax.delayedCall(10, this.showAll.bind(this))

    }

    doEventListeners(){

      if(this.block){
        if(this.props.slideData.name === this.props.carousel.slides[this.props.carousel.currentSlide].name){
          this.block.addEventListener('mouseover', this.hideme.bind(this))
          TweenMax.delayedCall(10, this.showAll.bind(this))
        }else{
         this.block.removeEventListener('mouseover', this.hideme.bind(this))
        }
      }
    }

    showAll(){
      this.tl.stop();
      if(this.block){
        TweenMax.to(this.block, 1, {autoAlpha:0})
      }
    }

    hideme(){
      if(this.block){
        this.tl.to(this.block, 0.5, {autoAlpha: 0})
          .to(this.block, 0.5, {autoAlpha: 1, delay: 1})
      }
    }

    render(){
        return(
            <div className="revealBlock" ref={(b)=>{this.block = b}}></div>
        )
    }
}
export default RevealBlock;