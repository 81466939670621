import React from 'react';

class AudioPlayer extends React.Component{

    constructor(){
        super();
        this.playingSoundFor = null;
    }

    componentDidMount(){
      this.handlePlay()
      this._audio.onended = () =>{
        this.props.carouselAudioEnded(this.props.carousel.currentSlide);
        this._audio.currentTime = 0;
      }
    }

    componentDidUpdate(){
      const carousel = this.props.carousel;
      if(carousel.dragging){
        const slide = this.props.carousel.currentSlide;
        setTimeout(()=>{
          if(this.props.carousel.dragging && this.props.carousel.currentSlide == slide){
            this.doPause()
          }
        }, 100) // increase this time if users seem to be clicking slower like retards.
      }   

      if (carousel.dragged){
        if(this._audio.paused){
          this.handlePlay()
        }else{
          if(this.playingSoundFor !== this.props.carousel.currentSlide){
            this.doPause();
            this.handlePlay();
          }
        }
      }
      this.checkSound()
    }

    checkSound(){
      if(this.props.carousel.soundOn){
        this._audio.volume = 1
      }else{
        this._audio.volume = 0
      }
    }

    doPause(){
      this._audio.pause();
      this.props.carouselSaveAudiotime(this.props.carousel.currentSlide, this._audio.currentTime);
    }

    shouldComponentUpdate(nextProps, nextState){
      return (nextProps.carousel.dragging !== this.props.carousel.dragging || nextProps.carousel.dragged !== this.props.carousel.dragged || this.props.carousel.soundOn !== nextProps.carousel.soundOn)
    }

    handlePlay(){
      if(this.props.carousel.slides[this.props.carousel.currentSlide].audioSrc != "" && this.props.carousel.slides[this.props.carousel.currentSlide].audioSrc != undefined){
        setTimeout(()=>{
          this._audio.setAttribute ('src', this.props.carousel.slides[this.props.carousel.currentSlide].audioSrc);
          // this._audio.currentTime = this.props.carousel.slides[this.props.carousel.currentSlide].audioTime;
          this._audio.load();
          this.checkSound()
          this._audio.play();
          this.playingSoundFor = this.props.carousel.currentSlide;

        }, 500)
      }
    }

    render(){

        return(
            <audio ref={(a)=>{this._audio = a}}></audio>
        )
    }
}
export default AudioPlayer;
