import React from 'react';
import TweenMax from 'gsap';

class BGVideoComp extends React.Component{

    constructor(){
        super();
        //
    }

    shouldComponentUpdate(nextProps) {
        return ((nextProps.carousel.currentSlide === this.props.thisSlideIndex) || (this.props.carousel.currentSlide === this.props.thisSlideIndex && nextProps.carousel.currentSlide !== this.props.thisSlideIndex))
    }

    componentDidMount(){
        if(this.refs.video_tag){
            this.refs.video_tag.play();
        }
    }

    componentWillUpdate(){
        if(this.refs.video_tag){
            this.refs.video_tag.pause();
        }
    }

    componentDidUpdate() {
        if (this.props.currentSlide === this.props.thisSlideIndex && this.props.vidSrc !== '') {
            setTimeout(() => {
                if(this.refs.video_tag){
                    this.refs.video_tag.play();
                }
            },2000);
        }else if (this.props.currentSlide !== this.props.thisSlideIndex && this.props.vidSrc !== '' || this.props.carousel.dragging) {
            if(this.refs.video_tag){
                this.refs.video_tag.pause();
            }
        }

    }

    loadThisVideo() {
    }

    renderBGVideo(vidSrc) {
        if(vidSrc != '') {
            return <video loop poster="" ref="video_tag" className="fullscreen-bg__video">
              <source src={`${vidSrc}.mp4`} type="video/mp4"/>
              <source src={`${vidSrc}.ogv`} type="video/ogv"/>
              <source src={`${vidSrc}.webm`} type="video/webm"/>
            </video>
        }else {
            return '';
        }
    }

    render(){
        return (
          <div>
            {this.renderBGVideo(this.props.vidSrc)}
          </div>
        )
    }
}
export default BGVideoComp;